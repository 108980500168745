import { useEffect, useRef, useState } from "react";
import WebFont from "webfontloader";
import MapComponent from "./components/mapComponent/MapComponent";
import { Wrapper } from "@googlemaps/react-wrapper";
import FuelsSearch from "./components/fuelsSearch/FuelsSearch";
import { Source } from "./utils/dataDefinitions/searchResultTypes";
import FuelsToggle from "./components/fuelsToggle/FuelsToggle";
import mapToggle from "./resources/mapToggle.svg";
import ZoomControl from "./components/zoomControl/ZoomControl";
import Parcel from "single-spa-react/parcel";
import React from "react";
import { getLocationByBounds, getStoreListViewData } from "./utils/externalCalls";
import { mountRootParcel, ParcelConfig } from 'single-spa';
import singleSpaReact from "single-spa-react";
import ReactDOM from 'react-dom';
import env from 'react-dotenv'

const remoteImport : ParcelConfig<{}> = () => System.import("@app/visops-toolbar");

export default function Root(props) {
  
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 41.124656,
    lng: -100.770216,
  });
  const [zoom, setZoom] = useState(5);
  const searchRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  function backToCurrentMap(flag: boolean) {
    if (!flag) {
      window.location.href = window.location.href.replace("new", "launch");
    }
  }

  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Roboto", "Arial"],
      },
    });
  }, []);
  function handleSearchResult(result: Source) {
    setCenter({
      lat: result.location.lat,
      lng: result.location.lon,
    });
  }
  return (
    <Wrapper
      apiKey={"AIzaSyD1TE3AO2uxwIkTvfvvXezofBNFZ98zau4"}
      libraries={["places"]}
    >
      <div className="mainContainer">
        <div className="toolAndMap">
          <div className="toolbarContainer">
            <Parcel
              config={remoteImport}
              customProps={{
                getLocationByBounds,
                setCenter: setCenter,
                getStoreListViewData: getStoreListViewData,
                }}
            />
          </div>
          <div className="mapContainer">
            <MapComponent
              center={center}
              zoom={zoom}
              serachRef={searchRef}
              setGlobalZoom={setZoom}
              // setCurrentCenter={setCurrentCenter}
            />
            <div className="zoomControlContainer">
              <ZoomControl
                handleZoomChange={(z) => setZoom(z)}
                zoomSetOutSideThisComponent={zoom}
              />
            </div>
          </div>
        </div>

        <div className="searchContainer">
          <div className="searchCentering">
            <FuelsSearch handleSearchResult={handleSearchResult} />
          </div>

          {/* google location search */}
          {/* <input
          type="text"
          name=""
          id=""
          value={userInput}
          placeholder="address, city, country, state or zip code"
          onChange={(e) => {
            setUserInput(e.target.value)}}
          ref={searchRef}
        /> */}
          <div className="backToCurrentMapContainer">
            <FuelsToggle
              onChange={backToCurrentMap}
              size={2.5}
              tooltip="Show old map view"
              icon={mapToggle}
              defaultValue={true}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
