import React, { Children } from "react";
import cssClasses from "./IndicatorSVGComponents.module.css";
type Props = {
  children: JSX.Element;
  isFlagSetByApi: boolean;
  flag: boolean;
  tooltip?: string;
};

function IndicatorSVGComponents({ children, isFlagSetByApi, flag, tooltip }: Props) {
  return (
    <div
      className={cssClasses.connectivityIndicator}
      title={tooltip}
    >
      {
        React.cloneElement(children, { classname: isFlagSetByApi
          ? flag === null
            ? cssClasses.noConnectionData
            : flag
            ? cssClasses.connected
            : cssClasses.disconnected
          : cssClasses.connectionDataLoading })
      }
    </div>
  );
}

export default IndicatorSVGComponents;
