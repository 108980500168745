import React, { useEffect, useRef, useState } from 'react'
import cssClasses from './ZoomControl.module.css'

type Props = {
    handleZoomChange : (zoom: number)=>void;
    zoomSetOutSideThisComponent : number;
}

function ZoomControl({handleZoomChange, zoomSetOutSideThisComponent}: Props) {
    const [zoom, setZoom] = useState(zoomSetOutSideThisComponent);
    
    const maxZoom = 22;
    const minZoom = 4;
    useEffect(() => {
        handleZoomChange(zoom)
    }, [zoom])

    useEffect(()=>{
        setZoom(zoomSetOutSideThisComponent)
    },[zoomSetOutSideThisComponent])
    
    function handleZoomIn(){
        if(zoom>=maxZoom){
            return;
        }
        setZoom(zoom+1)
    }
    function handleZoomOut(){
        if(zoom<=minZoom){
            return;
        }
        setZoom(zoom-1)
    }
  return (
    <div className={cssClasses.container}>
        <div className={cssClasses.zoomIn} onClick={handleZoomIn}>&#43;</div>
        <div className={cssClasses.zoomOut} onClick={handleZoomOut}>&#8722;</div>
    </div>
  )
}

export default ZoomControl