import React from "react";

type Props = {
  classname: string;
};

function ConnectionSVG({ classname }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 112.21 112.21"
    >
    <g id="Layer_2" data-name="Layer 2">
        <g id="new_cleared_icons" data-name="new cleared icons">
            <rect width="112.21" height="112.21" fill='#fff0'/>
            <path className={classname} d="M56.1,59.8a29.86,29.86,0,0,0-25,13.51,3.57,3.57,0,0,0,.43,4.49l2.13,2.12a3.52,3.52,0,0,0,5.5-.64,19.88,19.88,0,0,1,33.88,0,3.53,3.53,0,0,0,5.5.65l2.13-2.13a3.55,3.55,0,0,0,.43-4.48A29.84,29.84,0,0,0,56.1,59.8Z" />
            <circle className={classname} cx="56.1" cy="89.7" r="9.16" />
            <path className={classname} d="M56.1,20.86A68.64,68.64,0,0,0,3.56,45.23a3.55,3.55,0,0,0,.2,4.8l2.12,2.11a3.51,3.51,0,0,0,5.18-.26,58.77,58.77,0,0,1,90.09,0,3.5,3.5,0,0,0,5.17.26L108.45,50a3.55,3.55,0,0,0,.2-4.8A68.68,68.68,0,0,0,56.1,20.86Z" />
            <path className={classname} d="M56.1,39.48A50.1,50.1,0,0,0,16.69,58.59a3.52,3.52,0,0,0,.3,4.67l2.1,2.1A3.53,3.53,0,0,0,24.37,65a40.16,40.16,0,0,1,63.47,0,3.53,3.53,0,0,0,5.28.32l2.09-2.09a3.52,3.52,0,0,0,.31-4.66A50.08,50.08,0,0,0,56.1,39.48Z" />
        </g>
    </g>
    </svg>
  );
}

export default ConnectionSVG;
