import React, { useEffect, useState } from "react";
import cssClasses from "./SitePopUp.module.css";
import cross from "../../../resources/cross.svg";
// import connection from "../../../resources/wifi.svg";
// import { ReactComponent as ConnectionSVG } from './icons/wifi.svg'
// import { ReactComponent as ConnectionSVG } from '../../../resources/wifi.svg'
import {
  getPrices,
  getPriceUpdateStatus,
  getStoreConnectivity,
  getStoreDetails,
  getTransactionFillAlert,
} from "../../../utils/externalCalls";
import { Source } from "../../../utils/dataDefinitions/searchResultTypes";
import PricesComponent from "./pricesComponent/PricesComponent";
import { PriceDetails } from "../../../utils/dataDefinitions/getPricesTypes";
import axios from "axios";
import { useStateSetValueLater } from "../../../utils/customHooks/useStateSetValueLater";
import { GetStoreDetails } from "../../../utils/dataDefinitions/storeDetailsTypes";
import {images} from "./images";
import IndicatorSVGComponents from "./indicatorSVGComponents/IndicatorSVGComponents";
import ConnectionSVG from "./indicatorSVGComponents/connectionSVG/ConnectionSVG";
import DiscountSVG from "./indicatorSVGComponents/discountSVG/DiscountSVG";
import PriceSignSVG from "./indicatorSVGComponents/priceSignSVG/PriceSignSVG";
import { GetPriceUpdateStatus } from "../../../utils/dataDefinitions/getPriceUpdateStatusTypes";
type Props = {
  isOpen: boolean;
  source: Source;
  close: () => void;
};

function SitePopUp({ isOpen, source, close }: Props) {
  // const [priceDetails, setPriceDetails] = useState<PriceDetails>();
  const [
    priceDetails,
    setPriceDetailsByApi,
    isPriceDetailsSetByApi,
    resetPriceDetails,
  ] = useStateSetValueLater<PriceDetails>();
  const [
    storeConnectivityIndicator,
    setStoreConnectivityIndicatorByApi,
    isStoreConnectivityIndicatorSetByApi,
    resetStoreConnectivityIndicator,
  ] = useStateSetValueLater<boolean>();
  const [
    priceSignInd,
    setPriceSignIndByApi,
    isPriceSignIndSetByApi,
    resetPriceSignInd,
  ] = useStateSetValueLater<boolean>();

  useEffect(() => {
    let axiosSource = axios.CancelToken.source();
    getStoreDetails(source.importcode, axiosSource.token)
      .then(({ data }) => data)
      .then((data: GetStoreDetails) => {

        data.posParameters
          ? getStoreConnectivity({
              storeId: data.storeId,
              posType: data.posParameters?.posType,
              ipDnsName: data.connectionParameters?.ipDnsName,
              username: data.connectionParameters?.userName,
              password: data.connectionParameters?.password,
              port: data.connectionParameters?.port,
              protocol: data.connectionParameters?.connectionType,
              inBoundPath: data.connectionParameters?.inBoundPath,
              outBoundPath: data.connectionParameters?.outBoundPath,
            })
              .then(({ data }) => {
                setStoreConnectivityIndicatorByApi(data.status);
              })
              .catch(() => {
                setStoreConnectivityIndicatorByApi(null);
              })
          : setStoreConnectivityIndicatorByApi(null);
      })
      .catch(() => {
        setStoreConnectivityIndicatorByApi(null);
      });

    getPrices(source.importcode)
      .then(({ data }) => {
        setPriceDetailsByApi(data);
      })
      .catch((x) => setPriceDetailsByApi(null));
    // getPrices(source.importcode)
    //   .then(({ data }) => setPriceDetails(data))
    //   .catch((x) => setPriceDetails(null));
    getPriceUpdateStatus(source.importcode)
      .then(({ data }) => data)
      .then((data: GetPriceUpdateStatus) => {
        setPriceSignIndByApi(data.isPriceUpdateEnable);
      });

    getTransactionFillAlert(source.importcode).then(()=>{});
    return () => {
      axiosSource.cancel();
      resetPriceDetails();
      resetStoreConnectivityIndicator();
    };
  }, [source]);

  return (
    <div className={isOpen ? cssClasses.container : cssClasses.containerHidden}>
      <div className={cssClasses.close} onClick={close}>
        <img
          className={cssClasses.closeIcon}
          src={cross}
          alt={"cross"}
          height="14px"
        />
      </div>
      <div className={cssClasses.nameAndLogo}>
        <img
          className={cssClasses.logo}
          // src={icon}
          src={priceDetails ? images[priceDetails.icon] : ""}
          alt={"logo"}
          height="14px"
        />
        <h4>{source.displayname}</h4>
      </div>
      <main>
        <div className={cssClasses.indicators}>
          {/* <IndicatorSVGComponents
            isFlagSetByApi={isPriceSignIndSetByApi}
            flag={priceSignInd}
            tooltip={"Price Sign"}
          >
            <PriceSignSVG classname={""} />
          </IndicatorSVGComponents>
          <IndicatorSVGComponents
            isFlagSetByApi={isStoreConnectivityIndicatorSetByApi}
            flag={storeConnectivityIndicator}
            tooltip={"store discount"}
          >
            <DiscountSVG classname={""} />
          </IndicatorSVGComponents> */}
          <IndicatorSVGComponents
            isFlagSetByApi={isStoreConnectivityIndicatorSetByApi}
            flag={storeConnectivityIndicator}
            tooltip={"store connectivity indicator"}
          >
            <ConnectionSVG classname={""} />
          </IndicatorSVGComponents>
        </div>
        {
          <PricesComponent
            priceDetails={priceDetails}
            isPriceLoaded={isPriceDetailsSetByApi}
          />
        }
      </main>
    </div>
  );
}

export default SitePopUp;
