import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  SearchQueryResult,
  Source,
} from "../../utils/dataDefinitions/searchResultTypes";
import { getImportCodeSearchResultAsync } from "../../utils/externalCalls";
import cssClasses from "./FuelsSearch.module.css";
import searchIcon from "./search.svg";

/**
 * TODO 1: this search is not working. once we click on a search result
 *         all other cluster and markers are getting removed.
 *
 */

type Props = {
  handleSearchResult: (selected) => void;
};

function FuelsSearch({ handleSearchResult }: Props) {
  const [options, setOptions] = useState<Source[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    if (inputValue != "") {
      getOptions(inputValue, setOptions);
    }
  }, [inputValue]);

  const getOptions = useCallback(
    debounce((text, setOptions) => {
      getImportCodeSearchResultAsync(text).then((x) => {
        let queryResult: SearchQueryResult = null;
        queryResult = x;
        setOptions(queryResult.hits.hits.map((h) => h._source));
      });
    }, 200),
    []
  );

  function handleBlur(){
    setShowDropdown(false)
    setOptions([])
  }

  function handleChange(e: any) {
    setInputValue(e.target.value);
  }

  function selectFromSuggestion(option: Source) {
    setShowDropdown(false);
    setInputValue("");
    handleSearchResult(option);
  }

  return (
    <div className={showDropdown ? cssClasses.activeContainer :cssClasses.container}>
      <div className={showDropdown ? cssClasses.searchIconActive : cssClasses.searchIcon}>
        <img
          className={cssClasses.icon}
          src={searchIcon}
          alt={"search"}
          height="23px"
        />
      </div>
      <div className={cssClasses.itextInputContainer}>
        <input
          type="text"
          name="option"
          id="option"
          value={inputValue}
          onChange={handleChange}
          placeholder="Search Sites"
          onFocus={() => setShowDropdown(true)}
          onBlur={handleBlur}
          className={cssClasses.itextInput}
          autoComplete="off"
        />
        {inputValue ? (
          <p
            className={cssClasses.itextInputClear}
            onClick={() => setInputValue("")}
          >
            X
          </p>
        ) : null}
      </div>

      {showDropdown && (
        <div className={cssClasses.optionsDropdown} id="optionDropdown">
          {options.map((option) => {
            return (
              <div
                onMouseDown={() => selectFromSuggestion(option)}
                className={cssClasses.optionList}
                key={option.displayname}
              >
                {option.displayname}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default FuelsSearch;
