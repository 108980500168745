import { useEffect, useState } from "react";


/**
 * 
 * @returns array of value(null by default), a function (the data to be set as param), a boolean(indicates if the data is set yet or not), a function (to reset the data and the boolean)
 */
export function useStateSetValueLater<T>():[T, (valueFromApi: T)=>void, boolean, ()=>void]{
    const [value, setValue] = useState<T>(null)
    const [isStateSetByApi, setIsStateSetByApi] = useState<boolean>(false)
    function setValueByApi(valueFromApi: T){
        setValue(valueFromApi)
        setIsStateSetByApi(true)
    }

    function reset(){
        setValue(null)
        setIsStateSetByApi(false)
    }
    
    return [value, setValueByApi, isStateSetByApi, reset]
}