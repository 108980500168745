// import { GEO_SEARCH_BY_IMPORT_CODE } from "../constants/QueryConstants";
// import axios from "axios";
// import { ES_AUTH_HEADER, GEO_LOCATION_ES_URL } from "../constants/URL";

import axios, { CancelToken } from "axios";
import { debounce } from "lodash";
import constant from "../utils/constants/constant.json";
import {
  constructESHeader,
  constructHeader,
  formServiceUrl
} from "./apiUrlUtil";
import { StoreConnectivityPayload } from "./dataDefinitions/dtoDataTypes";
import { Bounds } from "./dataDefinitions/elasticsearchTypes";

// export const getLocationsDataDebounced = debounce(getLocationsData,300);

export const getLocationsDataDebounced = debounce(function (callback) {
  return callback();
}, 300);

export async function getLocationsData(
  bounds: Bounds,
  zoomLevel: number,
  countryCodes: string[],
  isClusterCall: boolean
) {
  const clusterSearchObj = JSON.stringify({
    size: "0",
    query: {
      bool: {
        must: [
          {
            terms: {
              countryCode: countryCodes,
            },
          },
          {
            range: {
              regularPrice: {
                gt: 0,
              },
            },
          },
        ],
        filter: [
          {
            match_all: {},
          },
          {
            geo_bounding_box: {
              location: {
                top_left: [bounds.west, bounds.north],
                bottom_right: [bounds.east, bounds.south],
              },
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
    aggs: {
      gridSplit: {
        geotile_grid: {
          bounds: {
            top_left: [bounds.west, bounds.north],
            bottom_right: [bounds.east, bounds.south],
          },
          field: "location",
          precision: zoomLevel,
          size: 65535,
        },
        aggs: {
          gridCentroid: {
            geo_centroid: {
              field: "location",
            },
          },
          "sum_of_Regular-grossProfit": {
            sum: {
              field: "pdisales.Regular-grossProfit",
            },
          },
          "sum_of_Regular-volume": {
            sum: {
              field: "pdisales.Regular-volume",
            },
          },
          "Average_of_Regular-price": {
            avg: {
              field: "regularPrice",
            },
          },
          flag_count: {
            nested: {
              path: "priceSign",
            },
            aggs: {
              online_offline_flag: {
                terms: {
                  field: "priceSign.online",
                },
              },
            },
          },
          countryCode: {
            terms: {
              field: "countryCode",
            },
          },
        },
      },
    },
  });

  const markerSearchObj = JSON.stringify({
    size: "150",
    query: {
      bool: {
        must: [
          {
            terms: {
              countryCode: countryCodes,
            },
          },
        ],
        filter: [
          {
            match_all: {},
          },
          {
            geo_bounding_box: {
              location: {
                top_left: [bounds.west, bounds.north],
                bottom_right: [bounds.east, bounds.south],
              },
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
  });

  const response = await axios.get(
    await formServiceUrl(
      constant.urlConstants.elasticsearch.name,
      constant.urlConstants.elasticsearch.geolocationSearch,
      new Map([
        ["source_content_type", "application/json"],
        ["source", isClusterCall ? clusterSearchObj : markerSearchObj],
      ])
    ),
    {
      headers: await constructESHeader(),
    }
  );

  return response.data;
}
export async function getLocationsDataWithListData(
  bounds: Bounds,
  zoomLevel: number,
  countryCodes: string[],
  isClusterCall: boolean,
  isListView: boolean,
  center: google.maps.LatLngLiteral
) {
  const clusterSearchObj = JSON.stringify({
    size: "5000",
    query: {
      bool: {
        must: [
          {
            terms: {
              countryCode: countryCodes,
            },
          },
          {
            range: {
              regularPrice: {
                gt: 0,
              },
            },
          },
        ],
        filter: [
          {
            match_all: {},
          },
          {
            geo_bounding_box: {
              location: {
                top_left: [bounds.west, bounds.north],
                bottom_right: [bounds.east, bounds.south],
              },
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
    stored_fields: "_source",
    script_fields: {
      distance: {
        script:
        `doc['location'].arcDistance(${center.lat},${center.lng}) * 0.000621371`,
      },
    },
    aggs: {
      gridSplit: {
        geotile_grid: {
          bounds: {
            top_left: [bounds.west, bounds.north],
            bottom_right: [bounds.east, bounds.south],
          },
          field: "location",
          precision: zoomLevel,
          size: 65535,
        },
        aggs: {
          gridCentroid: {
            geo_centroid: {
              field: "location",
            },
          },
          "sum_of_Regular-grossProfit": {
            sum: {
              field: "pdisales.Regular-grossProfit",
            },
          },
          "sum_of_Regular-volume": {
            sum: {
              field: "pdisales.Regular-volume",
            },
          },
          "Average_of_Regular-price": {
            avg: {
              field: "regularPrice",
            },
          },
          flag_count: {
            nested: {
              path: "priceSign",
            },
            aggs: {
              online_offline_flag: {
                terms: {
                  field: "priceSign.online",
                },
              },
            },
          },
          countryCode: {
            terms: {
              field: "countryCode",
            },
          },
        },
      },
    },
  });

  const markerSearchObj = JSON.stringify({
    size: "150",
    query: {
      bool: {
        must: [
          {
            terms: {
              countryCode: countryCodes,
            },
          },
        ],
        filter: [
          {
            match_all: {},
          },
          {
            geo_bounding_box: {
              location: {
                top_left: [bounds.west, bounds.north],
                bottom_right: [bounds.east, bounds.south],
              },
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
    stored_fields: "_source",
    script_fields: {
      distance: {
        script:
        `doc['location'].arcDistance(${center.lat},${center.lng}) * 0.000621371`,
      },
    },
    aggs: {
      gridSplit: {
        geotile_grid: {
          bounds: {
            top_left: [bounds.west, bounds.north],
            bottom_right: [bounds.east, bounds.south],
          },
          field: "location",
          precision: zoomLevel,
          size: 65535,
        },
        aggs: {
          gridCentroid: {
            geo_centroid: {
              field: "location",
            },
          },
          "sum_of_Regular-grossProfit": {
            sum: {
              field: "pdisales.Regular-grossProfit",
            },
          },
          "sum_of_Regular-volume": {
            sum: {
              field: "pdisales.Regular-volume",
            },
          },
          "Average_of_Regular-price": {
            avg: {
              field: "regularPrice",
            },
          },
          flag_count: {
            nested: {
              path: "priceSign",
            },
            aggs: {
              online_offline_flag: {
                terms: {
                  field: "priceSign.online",
                },
              },
            },
          },
          countryCode: {
            terms: {
              field: "countryCode",
            },
          },
        },
      },
    },
  });

  const response = await axios.get(
    await formServiceUrl(
      constant.urlConstants.elasticsearch.name,
      constant.urlConstants.elasticsearch.geolocationSearch,
      new Map([
        ["source_content_type", "application/json"],
        ["source", isClusterCall ? clusterSearchObj : markerSearchObj],
      ])
    ),
    {
      headers: await constructESHeader(),
    }
  );

  return response.data;
}

export async function getStoreDetails(
  storeId: string,
  cancelToken?: CancelToken
) {
  return axios.get(
    await formServiceUrl(
      constant.urlConstants.storeLocationMaster.name,
      constant.urlConstants.storeLocationMaster.getStoreDetails,
      new Map([["storeId", storeId]])
    ),
    {
      cancelToken: cancelToken,
      headers: constructHeader(),
    }
  );
}

export async function getPrices(storeId: string, cancelToken?: CancelToken) {
  return axios.get(
    (await formServiceUrl(
      constant.urlConstants.storeLocationUtil.name,
      constant.urlConstants.storeLocationUtil.locationBySiteId
    )) +
      "/" +
      storeId,
    {
      cancelToken: cancelToken,
      headers: constructHeader(),
    }
  );
}

export async function getTransactionFillAlert(
  storeId: string,
  cancelToken?: CancelToken
) {
  return axios.get(
    await formServiceUrl(
      constant.urlConstants.storeLocationUtil.name,
      constant.urlConstants.storeLocationUtil.transactionFillAlert,
      new Map([["storeID", storeId]])
    ),
    {
      cancelToken: cancelToken,
      headers: constructHeader(),
    }
  );
}
export async function getStoreConnectivity(
  data: StoreConnectivityPayload,
  cancelToken?: CancelToken
) {
  return axios.post(
    await formServiceUrl(
      constant.urlConstants.storeLocationUtil.name,
      constant.urlConstants.storeLocationUtil.testDynamicStoreConnectivity
    ),
    data,
    {
      cancelToken: cancelToken,
      headers: constructHeader(),
    }
  );
}
export async function getStoreListViewData(
  storeId: string,
  cancelToken?: CancelToken
) {
  return axios.get(
    await formServiceUrl(
      constant.urlConstants.storeLocationMaster.name,
      constant.urlConstants.storeLocationMaster.getStoreListViewData,
      new Map([["storeId", storeId]])
    ),
    {
      cancelToken: cancelToken,
      headers: constructHeader(),
    }
  );
}

function buildSearchQuery(queryText: string) {
  return {
    query: {
      bool: {
        should: [
          {
            wildcard: {
              importcode: {
                value: queryText + "*",
              },
            },
          },
          {
            wildcard: {
              displayname: {
                value: queryText + "*",
              },
            },
          },
        ],
      },
    },
  };
}

export const getImportCodeSearchResultAsync = async (query: string) => {
  const response = await axios.get(
    await formServiceUrl(
      constant.urlConstants.elasticsearch.name,
      constant.urlConstants.elasticsearch.geolocationSearch,
      new Map([
        ["source_content_type", "application/json"],
        ["source", JSON.stringify(buildSearchQuery(query))],
      ])
    ),
    {
      headers: await constructESHeader(),
    }
  );

  return response.data;
};

export async function getPriceUpdateStatus(
  storeId: string,
  cancelToken?: CancelToken
) {
  return axios.get(
    await formServiceUrl(
      constant.urlConstants.storeLocationMaster.name,
      constant.urlConstants.storeLocationMaster.getPriceUpdateStatus,
      new Map([["storeId", storeId]])
    ),
    {
      cancelToken: cancelToken,
      headers: constructHeader(),
    }
  );
}

export async function getLocationByBounds( //TODO try to merge this call with the main cluster/marker call
  bounds: Bounds,
  zoomLevel: number,
  countries: string[],
  center: google.maps.LatLngLiteral,
  isDiesel?: boolean,
  isCompetitor?: boolean,
  cancelToken?: CancelToken,
) {
  let locations: any = [];
  center = center ?? {
    lat: 41.124656,
    lng: -100.770216,
  }
  bounds = bounds ?? {
    north: 75.45,
    south: 5.9,
    west: -179.91,
    east: -38.02,
  }
  //this.listViewNewMapData = [];
  const listViewQuery: any = {
    size: "5000",
    query: {
      bool: {
        filter: [
          {
            match_all: {},
          },
          {
            geo_bounding_box: {
              location: {
                top_left: [bounds.west, bounds.north],
                bottom_right: [bounds.east, bounds.south],
              },
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
    stored_fields: "_source",
    script_fields: {
      distance: {
        script: `doc['location'].arcDistance(${center.lat},${center.lng}) * 0.000621371`,
      },
    },
  };

  if (isDiesel != null) {
    const productTypeArr = {
      match: {
        isdiesel: isDiesel,
      },
    };
    if (listViewQuery.query.bool.must) {
      listViewQuery.query.bool.must.push(productTypeArr);
    } else {
      listViewQuery.query.bool["must"] = [productTypeArr];
    }
  }
  if (isCompetitor != null) {
    const isCompetitorArr = {
      match: {
        isCompetitor: isCompetitor,
      },
    };
    if (listViewQuery.query.bool.must) {
      listViewQuery.query.bool.must.push(isCompetitorArr);
    } else {
      listViewQuery.query.bool["must"] = [isCompetitorArr];
    }
  }

  if (countries.length == 1) {
    let cntry = { term: { countryCode: countries[0] } };
    if (listViewQuery.query.bool.must) {
      listViewQuery.query.bool.must.push(cntry);
    } else {
      listViewQuery.query.bool["must"] = [cntry];
    }
  }

  const response = await axios.get(
    await formServiceUrl(
      constant.urlConstants.elasticsearch.name,
      constant.urlConstants.elasticsearch.geolocationSearch,
      new Map([
        ["source_content_type", "application/json"],
        ["size", "5000"],
        ["scroll", "1m"],
        ["source", JSON.stringify(listViewQuery)],
      ])
    ),
    {
      cancelToken: cancelToken,
      headers: await constructESHeader(),
    }
  );
  return response.data;
}
