import { debounce } from "lodash";
import { useCallback } from "react";

export function useDebounce(functionToDebounce: (...args: any) => any, waitTime: number) {
  const debouncedFunction = useCallback(
    debounce(functionToDebounce, waitTime),
    []
  );
  return debouncedFunction
}
