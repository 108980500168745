import { useRef, useEffect, useState } from "react";
import {
  getLocationByBounds,
  getLocationsData,
  getLocationsDataDebounced,
  getLocationsDataWithListData,
} from "../../utils/externalCalls";
import {
  Bounds,
  Bucket,
  Location,
  ElasticResponse,
} from "../../utils/dataDefinitions/elasticsearchTypes";
import {
  Hit,
  Hits,
  Source,
} from "../../utils/dataDefinitions/searchResultTypes";
import SitePopUp from "./sitePopUp/SitePopUp";
import greenMediumCluster from "../../resources/clusters/kpi-cluster-green-medium.svg";
import cssClasses from "./MapComponent.module.css";
import { iconToImage, images } from "./sitePopUp/images";
import { useDebounce } from "../../utils/customHooks/useDebounce";
import { publish } from "../../utils/dataTransferToParcel";
import constants from "../../utils/constants/constant.json"

//https://urldefense.com/v3/__https://www.youtube.com/watch?v=Xwcud1Qnnsw&t=101s__;!!GJsAII055wae8wY!e8WmYzVRcbC9f_Pmo6yd7NYtA9-BeJsvdlmWu-UU2BIcouhtmmM9BRttucoAH63Elvc2XURYOra6f8s7qwGyh2LenBp_$

/**
 * TODO 1: on small mouse pan/drag(if drag distance < `threshold distance`) don't call elasticsearch api.
 *         this will cause problem if user keeps panning the map on a little increment and no api call will happen
 *         even if the sum of all small movement is greater then the `threshold distance`.
 *         To solve this issue keep track of the position when the last api call hapenned, then if the total
 *         pan distance (sum of all small pan/drag) is more then the `threshold distance` then make anothe api call.
 *         for this calculate the distance between previous center's current pixel position and current
 *         center's pixel position and get the pixel distance between those two
 *
 * TODO 2:  once the `150 marker in viewport threshold` is reached after that getLocationData is not required
 *          for zoom in event, because there is nothing new data to load.
 *
 * TODO 3: Debounce the zoom in and pan event call.
 *
 * TODO 4: when the map viewport is changed then, until the new elasticsearch response come show a loader
 *         to indicate that the data is not updated yet.
 */
const styles = {
  height: "100vh",
};
const USA_CANADA_BOUNDS: Bounds = {
  north: 75.45,
  south: 5.9,
  west: -179.91,
  east: -38.02,
};

type Props = {
  center: google.maps.LatLngLiteral;
  zoom: number;
  serachRef: React.MutableRefObject<HTMLInputElement>;
  setGlobalZoom: (zoom: number) => void;
  // setCurrentCenter: (center: google.maps.LatLngLiteral) => void;
};

function MapComponent({
  center,
  zoom,
  serachRef,
  setGlobalZoom,
}: // setCurrentCenter,
Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [buckets, setBuckets] = useState<Bucket[]>(null);
  const [hits, setHits] = useState<Hit[]>(null);
  const [currentZoom, setCurrentZoom] = useState(zoom);
  const [currentCenter, setCurrentCenter] =
    useState<google.maps.LatLngLiteral>(center);
  const [bounds, setBounds] = useState(USA_CANADA_BOUNDS);
  const [map, setMap] = useState<google.maps.Map>(null);
  const markersRef = useRef<RichMarker[] | google.maps.Marker[]>(null);
  let placeSearchMarker: google.maps.Marker = null;
  const [isClusterView, setIsClusterView] = useState(true);
  const [isSitePopUpOpen, setIsSitePopUpOpen] = useState(false);
  const [activeImportCode, setActiveImportCode] = useState<Source>();
  const publishDebounced = useDebounce(publish,200);

  class RichMarker extends google.maps.OverlayView {
    private location: google.maps.LatLng;
    private image: string;
    private div?: HTMLElement;
    private importCode: string;
    private isCompetetor: boolean;
    private handleClick: () => void;

    constructor(
      loc: google.maps.LatLngLiteral,
      image: string,
      importCode: string,
      isCompetetor,
      handleClick: () => void
    ) {
      super();
      this.location = new google.maps.LatLng(loc);
      this.image = image;
      this.importCode = importCode;
      this.handleClick = handleClick;
      this.isCompetetor = isCompetetor;
    }

    /**
     * onAdd is called when the map's panes are ready and the overlay has been
     * added to the map.
     */
    onAdd() {
      const html = `<div class=${cssClasses.logoContainer}> <img src=${this.image} alt="" class="${cssClasses.logo}"/></div>
      <h6 class=${cssClasses.importCode} >${this.importCode}</h6>
      <div class=${cssClasses.markerPointer}></div>`;
      this.div = document.createElement("div");
      this.div.classList.add(
        this.isCompetetor
          ? cssClasses.markerContainerDivCompetetor
          : cssClasses.markerContainerDivOwn,
        cssClasses.markerContainerDiv
      );
      this.div.addEventListener("click", this.handleClick);
      this.div.innerHTML = html;
      const panes = this.getPanes()!;
      panes.overlayMouseTarget.appendChild(this.div);
    }

    draw() {
      const overlayProjection = this.getProjection();
      const sw = overlayProjection.fromLatLngToDivPixel(this.location)!;

      //only set the marker position from here
      if (this.div) {
        this.div.style.left = sw.x + "px";
        this.div.style.top = sw.y + "px";
      }
    }

    /**
     * The onRemove() method will be called automatically from the API if
     * we ever set the overlay's map property to 'null'.
     */
    onRemove() {
      if (this.div) {
        (this.div.parentNode as HTMLElement).removeChild(this.div);
        delete this.div;
      }
    }
  }

  const options = {
    fields: ["formatted_address", "geometry", "name"],
    strictBounds: false,
    types: ["establishment"],
  };

  function convertElasticLocationToGoogleLocation(elasticLoc: Location) {
    return {
      lat: elasticLoc.lat,
      lng: elasticLoc.lon,
    };
  }

  const createCluster = (
    map: google.maps.Map,
    // positions: google.maps.LatLngLiteral[],
    buckets: Bucket[]
  ) => {
    // const markers: google.maps.Marker[] = null;

    if (markersRef.current != null) {
      markersRef.current.forEach((m) => {
        m.setMap(null);
      });
    }

    markersRef.current = buckets?.map((bucket, ind) => {
      const googleLocation = convertElasticLocationToGoogleLocation(
        bucket.gridCentroid.location
      );
      const clusterVolume = bucket["sum_of_Regular-volume"].value;
      const clusterProfit = bucket["sum_of_Regular-grossProfit"].value;
      const clusterCPG =
        clusterVolume === 0
          ? 0
          : (-(-clusterProfit.toFixed(2)) / clusterVolume).toFixed(3);
      const clusterKPIs = `Profit: ${clusterProfit.toFixed(2)}
Volume: ${clusterVolume}
CPG: $${clusterCPG}
Cluster Count: ${bucket.doc_count}
Price: $${bucket["Average_of_Regular-price"].value.toFixed(2)}
${
  bucket.flag_count.online_offline_flag.buckets.length > 0
    ? `Price Sign Offline: ${bucket.flag_count.online_offline_flag.buckets[0]?.doc_count}/${bucket.flag_count?.doc_count}`
    : ""
}`;
      const marker = new google.maps.Marker({
        position: googleLocation,
        optimized: true,
        map,
        icon: {
          url: greenMediumCluster,
          scaledSize: new google.maps.Size(110, 110),
          anchor: new google.maps.Point(50, 50),
          labelOrigin: new google.maps.Point(55, 55),
        },
        title: clusterKPIs,
        label: {
          text:
            (bucket.countryCode.buckets[0].key === "CA" ? "C" : "") +
            "$" +
            bucket["Average_of_Regular-price"].value.toFixed(2),
          color: "white",
          fontSize: "12px",
          fontWeight: "700",
        },
      });

      marker.addListener("click", () => {
        map.panTo(googleLocation);
      });
      return marker;
    });
  };
  const createMarkers = (map: google.maps.Map, hits: Hit[]) => {
    // const markers: google.maps.Marker[] = null;

    if (markersRef.current != null) {
      markersRef.current.forEach((m) => {
        m.setMap(null);
        m = null;
      });
    }

    markersRef.current = hits?.map((hit, ind) => {
      const pos = convertElasticLocationToGoogleLocation(hit._source.location);
      const clickHandler = () => {
        // console.log("rich marker", hit._source.importcode)
        // setIsSitePopUpOpen(true);
        setActiveImportCode(hit._source);
        map.panTo(pos);
      };
      const image = images[iconToImage[hit._source.iconid]];
      const richMarker: RichMarker = new RichMarker(
        pos,
        image,
        hit._source.importcode,
        hit._source.isCompetitor,
        clickHandler
      );

      richMarker.setMap(map);
      return richMarker;
    });
  };

  useEffect(() => {
    if (isClusterView) {
      // getLocationByBounds(bounds, zoom, ["US", "CA"], center).then(({hits})=>hits.hits).then((hits:Hit[])=>{
      //   setCenter(center)
      //   console.log(hits.map((h)=>h.fields.distance[0]));
      // })
      Promise.all([
        getLocationsData(
          bounds,
          map?.getZoom() ?? zoom,
          ["US"],
          isClusterView
          // , true, center
        ),
        getLocationsData(
          bounds,
          map?.getZoom() ?? zoom,
          ["CA"],
          isClusterView
          // , true, center
        ),
      ]).then((elasticResponses: ElasticResponse[]) => {
        const totalHits = elasticResponses
          .map((es) => es.hits.total.value)
          .reduce((x, y) => x + y, 0);

        if (totalHits < 150) {
          setIsClusterView(false);
          return;
        }
        let buckets: Bucket[] = [];
        elasticResponses.forEach((es) => {
          buckets.push(...es.aggregations.gridSplit.buckets);
        });
        // console.log(buckets.map(b=>b.countryCode.buckets));
        setBuckets(buckets);
      });
    } else {
      getLocationsDataDebounced(() => {
        getLocationsData(
          bounds,
          map?.getZoom() ?? zoom,
          ["US", "CA"],
          isClusterView
          // , true, center
        ).then((elasticRes: ElasticResponse) => {
          if (elasticRes.hits.total.value > 150) {
            setIsClusterView(true);
            return;
          }
          setHits(elasticRes.hits.hits);
        });
      });
    }
  }, [bounds, isClusterView]);

  function placeSearch(map: google.maps.Map) {
    const autocomplete = new google.maps.places.Autocomplete(
      serachRef.current,
      options
    );
    autocomplete.bindTo("bounds", map);
    autocomplete.addListener("place_changed", () => {
      if (placeSearchMarker != null) {
        placeSearchMarker.setMap(null); //clear previous search marker
      }
      const place = autocomplete.getPlace();
      if (!place.geometry || !place.geometry.location) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        window.alert("No details available for input: '" + place.name + "'");
        return;
      }
      // If the place has a geometry, then present it on a map.
      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(17);
      }
      const marker = new google.maps.Marker();
      marker.setMap(map);
      marker.setPosition(place.geometry.location);
      marker.setVisible(true);
      placeSearchMarker = marker; //store current marker to clear it from map in the next search
      place.formatted_address;
    });
  }

  useEffect(() => {
    createCluster(map, buckets);
  }, [buckets]);

  useEffect(() => { 
    publishDebounced(constants.eventList.mapViewportUpdate, { center: currentCenter,
      zoom: currentZoom,
    bounds});
  }, [bounds]);

  useEffect(() => {
    createMarkers(map, hits);
  }, [hits]);
  useEffect(() => {
    map?.setZoom(zoom);
  }, [zoom]);

  useEffect(() => {
    map?.setCenter(center);
    map?.setZoom(20);
  }, [center]);

  useEffect(() => {
    const map = new window.google.maps.Map(ref.current as HTMLElement, {
      fullscreenControl: false,
      center: center,
      restriction: {
        latLngBounds: USA_CANADA_BOUNDS,
        strictBounds: true,
      },
      zoom: zoom,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
    });

    map.addListener("center_changed", () => {
      // console.log("center_changed");
      
      setCurrentCenter({
        lat: map.getCenter().lat(),
        lng: map.getCenter().lng(),
      });
    });
    map.addListener("zoom_changed", () => {
      const newBounds: Bounds = {
        north: map.getBounds().getNorthEast().lat(),
        south: map.getBounds().getSouthWest().lat(),
        west: map.getBounds().getSouthWest().lng(),
        east: map.getBounds().getNorthEast().lng(),
      };
      setGlobalZoom(map.getZoom());
      // console.log("zoom_changed");
      
      setCurrentZoom(map.getZoom());
      setBounds(newBounds);
    });
    map.addListener("dragend", () => {
      const newBounds: Bounds = {
        north: map.getBounds().getNorthEast().lat(),
        south: map.getBounds().getSouthWest().lat(),
        west: map.getBounds().getSouthWest().lng(),
        east: map.getBounds().getNorthEast().lng(),
      };
      setBounds(newBounds);
    });

    // const richMarker1: RichMarker = new RichMarker(center, image1, "123", ()=>console.log(""));
    // richMarker1.setMap(map);
    // new google.maps.Marker({position: center, map})

    setMap(map);
  }, [ref]);

  return (
    <div
      style={{
        width: "100%",
        overflowX: "hidden",
      }}
    >
      <div ref={ref} id="map" style={styles} />
      {activeImportCode && (
        <SitePopUp
          isOpen={isSitePopUpOpen}
          source={activeImportCode}
          close={() => setIsSitePopUpOpen(false)}
        />
      )}
    </div>
  );
}

export default MapComponent;
