import React from "react";
import { Price, PriceDetails } from "../../../../utils/dataDefinitions/getPricesTypes";
import cssClasses from "./PricesComponent.module.css";
type Props = {
  priceDetails: PriceDetails;
  isPriceLoaded: boolean;
};

const dummyData: Price[] = [
  {
    productName: "loading...",
    retail: null,
    effectiveDate: "... ...",
  },
  {
    productName: "loading...",
    retail: null,
    effectiveDate: "... ...",
  },
  {
    productName: "loading...",
    retail: null,
    effectiveDate: "... ...",
  },
];
const fuelProducts = [
  "regular",
  "plus",
  "supreme",
  "diesel"
]
function PricesComponent({ priceDetails, isPriceLoaded }: Props) {
  if (!isPriceLoaded) {
    return (
      <div className={cssClasses.container}>
        <h3>Prices</h3>
        <div className={cssClasses.singleSourcePricesContainer}>
          {dummyData.map((p, ind) => {
            return (
              <div key={p.productName + ind} className={cssClasses.onePriceContainer}>
                <h5>{p.productName}</h5>
                {/* <p className={cssClasses.priceLoading}>{p.retail.toFixed(2)}<sup>{p.retail.toString().at(-1)}</sup></p> */}
                <p className={cssClasses.priceLoading}>{p.retail}<sup>{p.retail}</sup></p>
                {p.effectiveDate.split(" ").map((e, i) => (
                  <p key={p.productName + e + i} className={cssClasses.dateTime}>
                    {e}
                  </p>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  return (
    <div className={cssClasses.container}>
      <h3>Prices</h3>
      {
        priceDetails ? 
        <>
      <div className={cssClasses.singleSourcePricesContainer}>
        <p className={cssClasses.priceSourceIndicator}>Price</p>
        {(priceDetails.prices.length !== 0) ? priceDetails.prices.map((p) => {
          return (
            <div key={p.productName} className={cssClasses.onePriceContainer}>
              <h5>{p.productName}</h5>
              <p className={cssClasses.price}>{"$"+p.retail.toFixed(2)}<sup>{p.retail.toString().at(-1)}</sup></p>
              {p.effectiveDate.split(" ").map((e) => (
                <p key={p.productName + e} className={cssClasses.dateTime}>
                  {e}
                </p>
              ))}
            </div>
          );
        }): <div>Price not returned</div>
        }
      </div>
      <div className={cssClasses.singleSourcePricesContainer}>
      <p className={cssClasses.priceSourceIndicator}>Opis</p>
        {(priceDetails.opisPrices.length!==0) ? priceDetails.opisPrices.map((p) => {
          return (
            <div key={p.productName} className={cssClasses.onePriceContainer}>
              <h5>{p.productName}</h5>
              <p className={cssClasses.price}>{p.retail.toFixed(2)}<sup>{p.retail.toString().at(-1)}</sup></p>
              {p.effectiveDate.split(" ").map((e) => (
                <p key={p.productName + e} className={cssClasses.dateTime}>
                  {e}
                </p>
              ))}
            </div>
          );
        }):
        <div>Opis Price not returned</div>}
      </div>
        </> : <div>Opis Price call failed</div>
      }
    </div>
  );
}

export default PricesComponent;
