import React, { useEffect, useRef, useState } from "react";
import FuelsToolTip from "../fuelsToolTip/FuelsToolTip";
import cssClasses from "./FuelsToggle.module.css";
/**
 * TODO 1: add tab index
 * TODO 2: trigger onChange on space key down 
 * TODO 3: add default state as props
 * TODO 4: make the size more adaptable to parent. e.g: should take 100% of parent's height and width
 *          should adjust according to aspect ratio 
 */



type Props = {
  onChange(flag: boolean): any;
  size?: number;
  tooltip?: string;
  icon?:string;
  defaultValue? : boolean;
};

/**
 * 
 * @prop `onChange` callback that triggers on change
 * @author Anirban Maji
 */
function FuelsToggle({onChange, size, tooltip, icon, defaultValue }: Props) {
  const [flag, setFlag] = useState(defaultValue??false);
  const [firstRender, setFirstRender] = useState(!defaultValue??true);
  const containerRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  function toggle(){
    setFlag(flag => !flag)
    setFirstRender(false)
  }

  useEffect(() => {
    if(!firstRender){
      setTimeout(()=>onChange(flag), 250)
    }
  },[flag, firstRender]);

  useEffect(() => {
    if (size)
      containerRef.current.style.setProperty("--containerWidth", size + "rem");
  }, [containerRef, size]);

  return (
    <div ref={containerRef} className={cssClasses.container} onClick={toggle} tabIndex={0}>
      <div  className={firstRender ? cssClasses.switch :(flag ? cssClasses.switchForward : cssClasses.switchReverse)}>
        {icon && <img src={icon} alt="icon" className={cssClasses.icon}/>}
      </div>
      {tooltip && <p className={cssClasses.tooltip}>{tooltip}</p>}
    </div>
  );
}

export default FuelsToggle;
