// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zf1JGKuIrovhmyGR4JNqTQ\\=\\= {\n  height: 30px;\n  width: 30px;\n}\n\n.BxULSooWDsyB3O-FcK5\\+IQ\\=\\= {\n  fill: rgba(128, 128, 128, 0.84);\n  /* stroke: black; */\n  /* stroke-width: 2 */\n}\n\n._1xPWWw98XbthCUSs0owmxQ\\=\\= {\n  fill: green;\n}\n\n._3MQEBr0AJP2KQ5VcxSoe4g\\=\\= {\n  fill: rgb(198, 0, 0);\n}\n\n.b8unkWIyO4BxDcxR2qsKew\\=\\= {\n  animation-name: HPQxCalaIEWTe9WXbC7zYw\\=\\=;\n  animation-iteration-count: infinite;\n  animation-duration: 500ms;\n  animation-timing-function: ease-in-out;\n  animation-direction: alternate;\n}\n\n@keyframes HPQxCalaIEWTe9WXbC7zYw\\=\\= {\n  from {\n    fill: rgb(197, 197, 197);\n  }\n  to {\n    fill: rgb(61, 61, 61);\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/mapComponent/sitePopUp/indicatorSVGComponents/IndicatorSVGComponents.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,+BAA+B;EAC/B,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,0CAA4B;EAC5B,mCAAmC;EACnC,yBAAyB;EACzB,sCAAsC;EACtC,8BAA8B;AAChC;;AAEA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,qBAAqB;EACvB;AACF","sourcesContent":[".connectivityIndicator {\n  height: 30px;\n  width: 30px;\n}\n\n.noConnectionData {\n  fill: rgba(128, 128, 128, 0.84);\n  /* stroke: black; */\n  /* stroke-width: 2 */\n}\n\n.connected {\n  fill: green;\n}\n\n.disconnected {\n  fill: rgb(198, 0, 0);\n}\n\n.connectionDataLoading {\n  animation-name: priceLoading;\n  animation-iteration-count: infinite;\n  animation-duration: 500ms;\n  animation-timing-function: ease-in-out;\n  animation-direction: alternate;\n}\n\n@keyframes priceLoading {\n  from {\n    fill: rgb(197, 197, 197);\n  }\n  to {\n    fill: rgb(61, 61, 61);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"connectivityIndicator": "zf1JGKuIrovhmyGR4JNqTQ==",
	"noConnectionData": "BxULSooWDsyB3O-FcK5+IQ==",
	"connected": "_1xPWWw98XbthCUSs0owmxQ==",
	"disconnected": "_3MQEBr0AJP2KQ5VcxSoe4g==",
	"connectionDataLoading": "b8unkWIyO4BxDcxR2qsKew==",
	"priceLoading": "HPQxCalaIEWTe9WXbC7zYw=="
};
export default ___CSS_LOADER_EXPORT___;
